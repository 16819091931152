/* ----------------------------------------------------------------------------
Information Contained Herein is Proprietary and Confidential. copyright notice in all copies, 
acknowledging that the code is provided without warranties, and strictly prohibiting unauthorized 
sharing or distribution without prior written consent from the copyright holder<DKG Labs Pvt. Ltd>
------------------------------------------------------------------------------ */
/* color: #010438 !important;
color: #104C92 !important;
color: #010438 !important; */

/* News and Blog Component Styles */
.news-blog-main{
  background-color: #010438 !important;
}
.head-news-blog{
  width: 100% !important; 
  background-color: #010438 !important;

}
.newsandblogdiv{
  background-color: #010438 !important;
  margin-bottom: 2rem;
  width: 100%  !important;
}
/* News Card Styles */
.news-card-unique {
  border: none !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

}

.news-image-unique {
  height: 100%;
  object-fit: cover;
}

.news-title-unique {
  font-size: 1.2rem;
  /* font-weight: bold; */
  margin-bottom: 0.5rem;
  color:#F37021;
}

.news-description-unique {
  color:#010438;
  overflow: hidden;
  height: 100px;

  /* margin-bottom: 1rem; */
}

.news-date-unique {
  font-size: 0.8rem;
  color:#F37021;
  /* font-weight: 700; */
}

/* Blog Card Styles */
.blog-card-unique {
  border: none !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.blog-image-unique {
  height: 100%;
  object-fit: cover;
  text-decoration: none !important;
}

.blog-title-unique {
  /* font-size: 1.2rem; */
  /* font-weight: bold; */
  margin-bottom: 0.5rem;
  color:#F37021;
  text-decoration: none !important;
}

.blog-description-unique {
  font-size: 1rem;
  color:#010438;
  /* margin-bottom: 1rem; */
  overflow: hidden;
  height: 100px;
}

.blog-date-unique {
  font-size: 0.8rem;
  color:#F37021 !important;
  font-weight: 700;
}

/* See All Button Styles */
.news-see-all-unique,
.blog-see-all-unique {
  font-size: 1rem;
  background-color:white !important;
  text-decoration: none;
  color:#010438 !important;
  font-weight: 600 !important;
  transition: all 0.3s ease-in-out;
}

.news-see-all-unique:hover,
.blog-see-all-unique:hover {
  color: #0056b3;
  text-decoration: none;
}

/* Heading Styles */
.news-heading-unique,
.blog-heading-unique {
  /* margin-top: 3rem; */
  font-size: 2em;
  /* font-weight: bold; */
  margin-bottom: 1rem;
  font-family: "Beltram-Regular", sans-serif;
  font-style: normal;
  /* font-weight: 700; */
  /* line-height: 45px; */
  text-align: center;
  color:#F37021;
}
.news-card-unique,
.blog-card-unique{
  color:#F37021 !important;
}
/* Horizontal Rule Styles */
hr {
  border-top: 1px solid white !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


/* ----------------------------------------------------------------------------
Information Contained Herein is Proprietary and Confidential. copyright notice in all copies, 
acknowledging that the code is provided without warranties, and strictly prohibiting unauthorized 
sharing or distribution without prior written consent from the copyright holder<DKG Labs Pvt. Ltd>
------------------------------------------------------------------------------ */
/* color: #010438 !important;
color: #104C92 !important;
color: #3100BE !important; */


.background-image {
  position: relative;
  width: 50%;
  background-size: cover;
  background-position: center;
  height: 70vh;
  display: flex;
  justify-content: flex-end;
}
.disp_cl{
  display: flex;
  justify-content: space-between;
}
.contact_img{
  width: 50%;
  height: auto;
  object-fit: cover;
}
.form-overlay {
  background-color: #fafafa;
 padding: 20px;
 backdrop-filter: blur(5px); 
  border-radius: 10px;
  justify-content: end;
  max-width: 550px;
  width: 100%;
  height:fit-content;
  margin-left: 30%;
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */
}

.form-overlay h2 {
  font-size: 28px;
  margin-bottom: 15px;
}

.form-overlay p {
  font-size: 16px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .disp_cl{
    display: flex;
    flex-direction: column;
  }
  .contact_img{
    display: none;
  }
  .form-overlay {
    padding: 20px;
    margin-left: auto;

  }

  .form-overlay h2 {
    font-size: 24px;
  }

  .form-overlay p {
    font-size: 14px;
  }
}


.supprt-main{

}
.support-page {
  font-family: "Beltram-Regular", sans-serif !important;  width:100%;
  background-color: white;
  max-width: 100% !important;
}
.btn-submit{
  background-color: #010438 !important;
}
.btn-submit:hover{
  background-color:#F37021 !important;
}
.supportpage-container {
  display: flex;
  flex-wrap: wrap;
}

.supportpage-map {
  width: 100%;
  height: 400px;
  margin-bottom: 30px;
}

.supportpage-address {
  display: flex;
  flex-direction: column;
  justify-content: top-center;
  /* align-items: flex-start; */
  background-color: #fafafa;
  padding: 20px; ;
  border-radius: 10px;
}

.supportpage-address p {
  margin: 10px 0;
  /* font-weight: 500; */
  color: #010438;
}

.supportpage-address p:first-child {
  margin-top: 0;
}

.supportpage-address p:last-child {
  margin-bottom: 0;
}

.supportpage-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fafafa;
  padding: 20px; ;
  border-radius: 10px;

}

.supportpage-form h2 {
  margin-bottom: 15px;
  color: #F37021;
}

.supportpage-form span {
  margin-bottom: 30px;
  color: #010438;
}

.supportpage-form label {
  margin-bottom: 5px;
  color: #010438;
}

.supportpage-form input,
.supportpage-form textarea {
  margin-bottom: 15px;
  border-radius: 3px;
  border: 1px solid #ced4da;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  color: #010438;
}

.supportpage-form button {
  margin-top: 15px;
}

@media (max-width: 576px) {
  .supportpage-address {
    align-items: center;
    text-align: center;
  }
}

/* ----------------------------------------------------------------------------
Information Contained Herein is Proprietary and Confidential. copyright notice in all copies, 
acknowledging that the code is provided without warranties, and strictly prohibiting unauthorized 
sharing or distribution without prior written consent from the copyright holder<DKG Labs Pvt. Ltd>
------------------------------------------------------------------------------ */
/* color: #010438 !important;
color: #104C92 !important;
color: #010438 !important; */

/* .top_head{
  background-color: #F37021;
  border: none;
  clip-path: ellipse(65% 100% at center top);
  height: auto;
  min-height: 270px;
  color: white;
  font-weight: 600;
  text-align: center;
  font-family: 'Salesforce-Avant-Garde', system-ui, -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, 'Helvetica Neue', arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.top_head h1{
  margin: 2rem !important;
}
.new-button{
 background-color: #f5f5f5 !important;
 color: #010438 !important;
 font-weight: 600 !important;
} */
/* NBFCPage.css */
.nbfc-page {
  font-family: "Beltram-Regular", sans-serif !important;  color: #010438;
  /* background-color: #f9f9f9; */
  background-color: white;
}
.onbording-text{
  color: #010438;
  margin-top: 4%;
  font-weight: 600;
}
.onbording-text img{
  height: 500px ;
}
#nbfcicongrid .MuiSvgIcon-root{
  font-size: 4rem !important;
}
.header {
  background-color: white;
  color: #010438;
  padding: 50px 50px 15px 50px;
  /* margin-bottom: 30px; */
}

.header-content {
  display: flex;
  
  flex-direction: row; /* Horizontal layout */
  align-items: flex-start; /* Align items at the top */
  justify-content: space-between; /* Space out the text and image */
}

.text-container {
  flex: 1; /* Allow text to take available space */
  margin-right: 20px; /* Space between text and image */
}
.why_part_grid:hover{
  transform: scale(1.05); 
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2); 
  transition: transform 0.3s ease,
}
.text-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 700;
  color: #F37021;
}

.text-container p {
  font-size: 1.1rem;
  line-height: 1.5; /* Improved readability */
}

/* Image styling */
.image-container {
  flex: 1; /* Allow image container to take available space */
  text-align: right; /* Align image to the right */
}

.image-container img {
  max-width: 100%; /* Ensure image is responsive */
  height: auto; /* Maintain aspect ratio */
  max-height: 500px; /* Limit max height */
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .header-content {
      flex-direction: column; /* Stack items vertically on smaller screens */
      align-items: center; /* Center items */
  }

  .text-container {
      margin-right: 0; /* Remove margin on mobile */
      text-align: center; /* Center text */
  }

  .image-container {
      margin-top: 20px; /* Space between text and image on mobile */
      text-align: center; /* Center image */
  }
}


.main-content {
  margin: 0 auto;
  padding: 5px;
  max-width: 1200px;
}

.main-content h2 {
  color: #F37021;
  font-size: 2rem;
  margin-bottom: 20px;
}

.benefits-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.benefit-card {
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  flex: 1 1 calc(33% - 20px);
  text-align: center;
}

.benefit-card h3 {
  color: #F37021;
  margin-bottom: 15px;
}

.market-trends {
  background-color: #010438; /* Background color for the trends section */
  color: white; /* Text color */
  padding: 50px; /* Padding for the section */
  margin-bottom: 30px; /* Margin below the section */
}

.market-trends-content {
  display: flex; /* Flexbox layout */
  flex-direction: row; /* Horizontal layout */
  justify-content: space-between; /* Space out text and graph */
  align-items: flex-start; /* Align items at the top */
}

.text-container {
  flex: 1; /* Allow text container to take available space */
  margin-right: 20px; /* Space between text and graph */
}

.text-container h2 {
  font-size: 2rem; /* Heading size */
  margin-bottom: 10px; /* Margin below heading */
}

.text-container p {
  font-size: 1.1rem; /* Paragraph size */
  line-height: 1.5; /* Improved readability */
}

/* Graph container */
.graph-container {
  background-color: #f5f5f5;
  flex: 1; /* Allow graph container to take available space */
  text-align: right; /* Align graph to the right */
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .market-trends-content {
      flex-direction: column; /* Stack items vertically on smaller screens */
      align-items: center; /* Center items */
  }

  .text-container {
      margin-right: 0; /* Remove margin on mobile */
      text-align: center; /* Center text */
  }

  .graph-container {
      margin-top: 20px; /* Space between text and graph on mobile */
      text-align: center; /* Center graph */
  }
}
.graph-container {
  width: 100%; /* Ensure it takes full width */
  max-width: 600px; /* Optional: Set a max-width for larger screens */
  margin: 0 auto; /* Center the graph */
  padding: 20px; /* Add some padding */
}

canvas {
  width: 100% !important; /* Ensure canvas is 100% width */
  height: auto !important; /* Allow height to adjust automatically */
}
.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.benefit-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, background-color 0.3s;
  margin: 10px; /* Adding margin */
}

.benefit-card h3 {
  color: #F37021; /* Updated header color */
}

.benefit-card p {
  color: #010438; /* Darker text for better readability */
}

.benefit-card:hover {
  background-color: #010438; /* Hover background color */
  color: white !important; /* Change text color on hover */
  transform: scale(1.05); /* Scale effect on hover */
}
 .benefit-card:hover p{
  color: white !important;
}

.onboarding-process{
  padding: 7px;
}
.onboarding-process ol {
  padding-left: 20px;
}

.cta-section {
  text-align: center;
  padding: 50px;
  color: #010438;
}

.cta-button {
  background-color: #F37021;
  color: white;
  padding: 10px 30px;
  margin-top: 0%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
}
.icon_Onboarding .MuiSvgIcon-root{
  width: 3.4em !important;
  height: 3.4em !important;
}
.cta2-button {
  background-color: #F37021;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
}

.cta-button:hover {
  background-color: #e3641a;
}
@media (max-width: 768px) {
  .benefits-grid {
      flex-direction: column;
  }
  .icon_Onboarding .MuiSvgIcon-root{
    width: 1em !important;
    height: 1em !important;
  }
  .benefit-card {
      flex: 1 1 100%;
  }

  .header h1 {
      font-size: 2rem;
  }

  .cta-button {
      font-size: 1rem;
      align-items: center;
  }
}







.nbfc_heading{
  color: #F37021;
  text-align: center;
  padding-bottom: 10px;
}
.about-nbfc-container {
  max-width: 100%;
  background:#f5f5f5 ;
  margin: 0 auto;
  padding: 5% !important;
  margin: 3% ;
}

.about-nbfc-container h1 {
  font-size: 36px;
  /* font-weight: bold; */
  text-align: center;
  margin-bottom: 20px;
  color: #F37021;
}

.about-nbfc-container h2 {
  font-size: 28px;
  /* font-weight: bold; */
  margin-top: 40px;
  margin-bottom: 20px;
}

.about-nbfc-container p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #010438;
}
h5{
  color: #F37021 !important; 
}

.nbfc-card {
  margin-top: 40px;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nbfc-card .card-body {
  padding: 30px;
}

.nbfc-card .card-title {
  font-size: 24px;
  /* font-weight: bold; */
  margin-bottom: 20px;
}

.nbfc-card .card-text {
  line-height: 1.5;
  margin-bottom: 0;
}

.nbfc-card ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nbfc-card ul li {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.5;
}

.nbfc-card ul li:before {
  content: "•";
  margin-right: 10px;
}

.btn-primary {
  border-color: #007bff;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 30px;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}


/* ----------------------------------------------------------------------------
Information Contained Herein is Proprietary and Confidential. copyright notice in all copies, 
acknowledging that the code is provided without warranties, and strictly prohibiting unauthorized 
sharing or distribution without prior written consent from the copyright holder<DKG Labs Pvt. Ltd>
------------------------------------------------------------------------------ */
/* color: #3100BE !important;
color: #104C92 !important;
color: #3100BE !important; */

.nbfc-registration-form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.nbfc-registration-form {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.nbfc-registration-form h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.nbfc-registration-form h3 {
  margin-top: 30px;
  margin-bottom: 10px;
}

.nbfc-registration-form label {
  /* font-weight: bold; */
}

.nbfc-registration-form div {
  width: 500px;
  margin: 20px;
}



.nbfc-registration-form input[type="text"],
.nbfc-registration-form input[type="email"],
.nbfc-registration-form input[type="date"],
.nbfc-registration-form input[type="file"],
.nbfc-registration-form select,
.nbfc-registration-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.nbfc-registration-form input[type="file"] {
  margin-bottom: 20px;
}

.nbfc-registration-form button[type="submit"] {
  width: 80%;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.nbfc-registration-form button[type="submit"]:hover {
  background-color: #010438;
}

/* Add more styles as needed */

/* ----------------------------------------------------------------------------
Information Contained Herein is Proprietary and Confidential. copyright notice in all copies, 
acknowledging that the code is provided without warranties, and strictly prohibiting unauthorized 
sharing or distribution without prior written consent from the copyright holder<DKG Labs Pvt. Ltd>
------------------------------------------------------------------------------ */
/* color: #010438 !important;
color: #104C92 !important;
color: #010438 !important; */


.emi-container {
  width: 100%;
  background-color: #f2f2f2;
  align-items: center;
  
}
.emi-body {
  padding: 0 1.5rem;
}
.emi-monthlyPayment{
  margin: 2rem;
  color: #010438;
  font-size: 1.5rem;
  text-align: center;
  line-height: revert;
}
.emi-monthlyPayment span{
  color: black;
}

.emidetials {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 120%;

}
.emidetials p{
  color: #010438;
}
.tenure-toggle {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between input and toggle */
}
.schedule_Table_head thead{
  background: #010438 !important;
  color: white;
}
.summary_table{
  background: #010438;
  color: white;
  margin-bottom: 2%;
}
.summary_table h3{
  padding: 2%;
}

.tenure_toggle_parent {
  display: flex;
  background-color: rgb(154, 161, 168);
  padding: 5px;
  border-radius: 3%;
}

.tenure-option {
  cursor: pointer;
  padding: 5px 10px;
}

.tenure-option.selected {
  background-color: #010438;
  color: white;
  border-radius: 3%;
}

.emi-interest {
  font-size: larger;
  font-weight: bold;
}

.emi-principal {
  font-size: larger;
  font-weight: bold;
}

.emi-payable {
  font-size: larger;
  font-weight: bold;
}

.emi-header {
  font-size: larger;
  font-weight: bold;
  padding: 2rem;
  /* border: 2px solid green; */
  text-align: center;
}
.emi-header h1 {
  color: #010438;
  font-weight: bolder;

}

.emi-container form {
  margin-bottom: 40px;
}

.emi-container form label {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.emi-container form .form-control {
  padding: 0.375rem 0.25rem;
}

input[type="range"] {
  /* -webkit-appearance: none; */
  margin: 10px 0;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #010438;
  border-radius: 10px;
}

input[type="range"]::-webkit-slider-thumb {
  /* box-shadow: 2px 2px 5px #b3b3b3, -1px -1px 3px grey; */
  border: 5px solid #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #010438;
  cursor: pointer;
  margin-top: -6px;
  -webkit-appearance: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #b3b3b3;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #010438;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
  border-radius: 10px;
}

input[type="range"]::-moz-range-thumb {
  /* box-shadow: 2px 2px 5px #b3b3b3, -1px -1px 3px grey; */
  border: 5px solid #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #010438;
  cursor: pointer;
  margin-top: -6px;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #010438;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
  border-radius: 10px;
}

input[type="range"]::-ms-fill-lower {
  background: #010438;
  border-radius: 5px;
}

input[type="range"]::-ms-fill-upper {
  background: #010438;
  border-radius: 5px;
}

input[type="range"]::-ms-thumb {
  /* box-shadow: 2px 2px 5px #b3b3b3, -1px -1px 3px grey; */
  border: 5px solid #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #010438;
  cursor: pointer;
  margin-top: -6px;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #010438;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #010438;
}

.emi-container .chart-container {
  margin-bottom: 40px;
}

.emi-container .chart-container canvas {
  display: block;
  width: 100%;
  height: auto;
}


.emi-details {
  background-color: #1d004c !important;
  padding: 2%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for professional look */
}
.table-responsive {
  margin-bottom: -2.5% !important;

}

.custom-table {
  background-color: #1d004c !important;
  border-radius: 8px;
  overflow: hidden;
}

.custom-table th, 
.custom-table td {
  padding: 12px 15px;
  font-size: 16px;
  text-align: left;
}

@media (max-width: 768px) {
  .custom-table th, 
  .custom-table td {
    font-size: 14px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .custom-table th, 
  .custom-table td {
    font-size: 12px;
    padding: 8px;
  }
  .table-responsive {
    margin-bottom: -5% !important;
  
  }
}


@media screen and  (max-width: 900px) {
  .emidetials{
    width: 90%;
    margin-left: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
}

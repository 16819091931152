/* ----------------------------------------------------------------------------
Information Contained Herein is Proprietary and Confidential. copyright notice in all copies, 
acknowledging that the code is provided without warranties, and strictly prohibiting unauthorized 
sharing or distribution without prior written consent from the copyright holder<DKG Labs Pvt. Ltd>
------------------------------------------------------------------------------ */
/* color: #010438 !important;
color: #104C92 !important;
color: #010438 !important; */


.registration-form-container {
  margin: 50px;
  max-width: 600px;
}

.registration-form {
  margin-top: 20px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.registration-form div {
  width: 500px;
  margin: 20px;
}
.registration-form label {
  font-weight: bold;
}

.registration-form input[type="text"],
.registration-form input[type="email"],
.registration-form input[type="tel"],
.registration-form input[type="date"],
.registration-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.registration-form input[type="file"] {
  margin-bottom: 20px;
}

.registration-form button[type="submit"] {
  margin-top: 20px;
}

.registration-form h2 {
  margin-bottom: 20px;
}

.registration-form select option:first-child {
  color: #ccc;
}


.registration-form button[type="submit"] {
  width: 80%;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.registration-form button[type="submit"]:hover {
  background-color: #0069d9;
}
.registration-form select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* ----------------------------------------------------------------------------
Information Contained Herein is Proprietary and Confidential. copyright notice in all copies, 
acknowledging that the code is provided without warranties, and strictly prohibiting unauthorized 
sharing or distribution without prior written consent from the copyright holder<DKG Labs Pvt. Ltd>
------------------------------------------------------------------------------ */
/* color: #010438 !important;
color: #104C92 !important;
color: #010438 !important; */



.about-dealer-container {
  max-width: 100% !important;
  background: #f5f5f5;
  margin: 0 auto;
  padding: 1% 5% !important;

}

.about-dealer-container h1 {
  font-size: 36px;
  /* font-weight: bold; */
  text-align: center;
  margin-bottom: 20px;
  color: #F37021;
}

.about-dealer-container h2 {
  font-size: 28px;
  /* font-weight: bold; */
  margin-top: 40px;
  margin-bottom: 20px;
  color: #F37021;
}

.about-dealer-container p {
  line-height: 1.5;
  margin-bottom: 20px;
  color: #010438;
}
.subheading-dealer{
  background-color: #010438;
  color: white;
  padding: 0.5em;
  border-radius: 5px;
  box-shadow:  2px 2px 2px 2px rgb(4, 6, 55);
}
.dealer-card {
  margin-top: 40px;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dealer-card .card-body {
  padding: 30px;
}

.dealer-card .card-title {
  font-size: 24px;
  color: #F37021;
  font-weight: bold;
  margin-bottom: 20px;
}

.dealer-card .card-text {
  /* font-size: 18px; */
  line-height: 1.5;
  margin-bottom: 0;
}

.dealer-card ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dealer-card ul li {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.5;
}

.dealer-card ul li:before {
  content: "•";
  margin-right: 10px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 30px;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}

/* ----------------------------------------------------------------------------
Information Contained Herein is Proprietary and Confidential. copyright notice in all copies, 
acknowledging that the code is provided without warranties, and strictly prohibiting unauthorized 
sharing or distribution without prior written consent from the copyright holder<DKG Labs Pvt. Ltd>
------------------------------------------------------------------------------ */
/* color: #010438 !important;
color: #104C92 !important;
color: #010438 !important; */
/* for orange */
/* color : #F37021 */


/* .FeactureSection{
  padding: 5rem 9rem ;
} */

.card_section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  z-index: 660;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.card_body {
  height: 300px;
  width: 95%;
  padding: 20px 8px;
  background-color:#010438 !important;
  color: #fff;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  /* box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.25); */
  border-radius: 8px;
  margin: 20px 5px !important ;
}
.text-center{
  width: 40%;
  
}
.card_button {
  background: #fff !important;
  color: #010438 !important;
  border: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  /* font-weight: 700 !important; */
}

.card_icon {
  width: 80px;
  height: 75px;
  padding: 10px;
}
.sec-fec-banner{
  width: 100% !important;
  /* margin-left: -10%; */
}
/* feature_banner */

.feature_banner {

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 50px 15px;
  gap: 60px;
  background: #ffffff;
}

.banner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 35px;
  /* align-items: center; */
  /* gap: 10rem; */
}

.banner_heading {
  color:  #F37021 !important;
  /* font-weight: bold; */
}
.para-text{
  color: #010438;
  /* font-weight: 500; */
}
.banner_card{
  display: flex;
  flex-wrap: wrap;
  justify-content:space-around;
  padding: 0 20px;
  /* box-shadow: 0px 4px 4px rgba(244, 230, 230, 0.25); */

}
.cards {
  margin: 0 0 0 4rem;
  align-content: center;
  justify-content: center;
   box-shadow: 0px 1px 2px rgba(244, 230, 230, 0.25) !important;

}

.banner_card_body {
  width: 40%;
  border-radius: 8px;
  background-color: #010438 !important;
  color:#F37021 !important ;
  color:white !important ;
  margin: 1%;

}

.banner_card_title {
  color: #010438;
  /* font-weight: bold; */
}

/* @media (min-width: 576px) {

  .card_section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* padding: 80px 40px 40px; */
  /* }
} */ 

@media (max-width: 576px) {
  .banner_card {
    padding: 10 ;
    flex-direction: column;
  }
  .banner_card_body{
    width: 100%;
    margin: 10px;
  }
  
}

@media (min-width: 768px) {
  /* .card_section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
  } */
  .cards {
    width: 28rem !important;
  }
}
@media (min-width: 992px) {
  .banner_card {
    padding: 0 60px;
  }
}

@media (max-width: 767px) {
  .cards {
    width: 100% !important;
    margin: 0;
  }
  .card_body{
    width:300px;
  }

  .banner-video {
    /* width: fit-content; */
    margin: 1rem;
    border-radius: 2%;
  }
}
